var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataLoaded)?_c('div',{staticClass:"p-4 bg-white rounded-lg"},[_c('DataTable',{ref:"dataTable",attrs:{"table_state":_vm.table_state,"store_names":_vm.store_names,"addComponent":_vm.componentForAdding,"editComponent":_vm.componentForEditing,"detailsComponent":_vm.componentForDetails,"delete_url":_vm.delete_url,"isFilterRequiredForExport":true,"urls":_vm.urls,"isWarehouseRequired":true,"actionsComponent":null,"recordDetailComponent":_vm.componentForRecordDetail},scopedSlots:_vm._u([{key:"topRightActions",fn:function({
        updateTable,
        permissions,
        pageLoader,
        selectedItems,
      }){return [(permissions('on_hold'))?_c('div',{staticClass:"mr-md-2 mr-sm-1 mb-1 mr-0 pa-0",class:{ 'col-6 mx-8': _vm.$vuetify.breakpoint.xsOnly }},[_c('button',{staticClass:"btn btn--export-filter font-weight-bolder",on:{"click":function($event){$event.preventDefault();_vm.actionOnHold([...selectedItems.map((item) => item.id)])}}},[_vm._v(" On Hold ")])]):_vm._e(),(permissions('release'))?_c('div',{staticClass:"mr-md-2 mr-sm-1 mb-1 mr-0 pa-0",class:{ 'col-6 mx-8': _vm.$vuetify.breakpoint.xsOnly }},[_c('button',{staticClass:"btn btn--export-filter font-weight-bolder",on:{"click":function($event){$event.preventDefault();_vm.actionRelease([...selectedItems.map((item) => item.id)])}}},[_vm._v(" Release ")])]):_vm._e()]}},{key:"rowActions",fn:function({ item, pageLoader, permissions, updateTable }){return [_c('v-menu',{attrs:{"left":"","transition":"scale-transition","offset-y":"","bottom":"","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"},'a',attrs,false),on),[_vm._v(" Actions "),_c('span',{staticClass:"svg-icon svg-icon-5 m-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)])]}}],null,true)},[_c('v-card',{staticClass:"poppins py-1",attrs:{"elevation":"1"}},[(permissions('on_hold'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.detail.actions.on_hold
                  ? 'text-muted'
                  : 'text-dark',attrs:{"disabled":!item.extra_data.detail.actions.on_hold,"text":""},on:{"click":function($event){return _vm.actionOnHold([item.id])}}},[_vm._v(" On Hold ")])],1):_vm._e(),(permissions('release'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.detail.actions.release
                  ? 'text-muted'
                  : 'text-dark',attrs:{"disabled":!item.extra_data.detail.actions.release,"text":""},on:{"click":() => {
                  _vm.actionRelease([item.id]);
                }}},[_vm._v("Release ")])],1):_vm._e(),(permissions('update'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.detail.actions.edit
                  ? 'text-muted'
                  : 'text-dark',attrs:{"disabled":!item.extra_data.detail.actions.edit,"text":""},on:{"click":() => {
                  _vm.actionEdit(item);
                }}},[_vm._v("Edit ")])],1):_vm._e(),(permissions('copy_inventory'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.detail.actions.copy
                  ? 'text-muted'
                  : 'text-dark',attrs:{"disabled":!item.extra_data.detail.actions.copy,"text":""},on:{"click":() => {
                  _vm.actionCopy(item);
                }}},[_vm._v("Copy ")])],1):_vm._e()])],1)]}}],null,false,1009809420)}),_c('OnHold',{ref:"onHold",attrs:{"set-reason-type":_vm.setReasonType}}),_c('EditItem',{ref:"editItem",attrs:{"refresher":_vm.loadTableData}}),_c('EditItem',{ref:"copyItem",attrs:{"mode":"copy","refresher":_vm.loadTableData}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }